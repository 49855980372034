<!-- 底部框架 -->
<template>
  <footer class="footer">
    <div class="contact">
      <div class="item">
        <img src="../assets/phone.png" alt="" class="icon" />
        <div class="label">招商热线</div>
        <div class="value">{{ $store.globalSet.mobile }}</div>
      </div>
      <div class="item">
        <img src="../assets/email.png" alt="" class="icon" />
        <div class="label">联系邮箱</div>
        <div class="value">{{ $store.globalSet.email }}</div>
      </div>
      <div class="item">
        <img src="../assets/internet.png" alt="" class="icon" />
        <div class="label">黑水县人民政府</div>
        <div class="value link" @click="skipTo">
          {{ $store.globalSet.peopleGovernment }}
        </div>
      </div>
    </div>
    <div class="icp">
      {{ $store.globalSet.copyRightMsg + $store.globalSet.icpMsg }}
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    skipTo() {
      window.open(this.$store.globalSet.peopleGovernment);
    },
  },
};
</script>

<style lang='scss' scoped>
.footer {
  min-width: 1100px;
  height: 90px;

  &::before {
    content: "";
    display: table;
  }
}
.contact {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  .item {
    margin-right: 60px;
    font-size: 14px;
    display: flex;

    &:last-child {
      margin-right: 0;
    }

    .icon {
      width: 21px;
      margin-right: 10px;
    }
    div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
    .value {
      color: #06458d;
    }
    .link {
      cursor: pointer;
    }
  }
}
.icp {
  font-size: 10px;
  margin-top: 10px;
  color: #999;
  text-align: center;
}
</style>
